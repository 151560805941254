// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  GET_GLOBAL_EMAIL, GET_GLOBAL_EMAILS, SEND_EMAIL, TEST_GLOBAL_EMAILS,
} from '@/@constants/mutations'
import * as emailStatuses from '@/@constants/emailStatuses'

const config = {}

export default {
  namespaced: true,

  actions: {
    [GET_GLOBAL_EMAILS]: (_, {
      filters,
      sorter,
      searchQuery,
      pagination,
    }) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = 'GLOBAL_EMAILS'

      if (filters) {
        if (filters.status && filters.status !== emailStatuses.ALL) params.eq_mailboxFolder = filters.status
        if (filters.selectedEmail) params.eq_userMailbox = filters.selectedEmail
        if (filters.messageIds) params.eqArr_msgId = JSON.stringify(filters.messageIds)
      }

      if (sorter) {
        const sortDir = sorter?.sortDesc ? 'desc' : 'asc'
        const sortBy = sorter?.sortBy || 'id'
        params.orderBy = `${sortBy}.${sortDir}`
      }

      if (pagination) {
        params.page = pagination.page
        params.limit = pagination.perPage
      }

      if (searchQuery && searchQuery.length) {
        // eslint-disable-next-line no-underscore-dangle
        const _search = searchQuery.replaceAll(' ', '%')
        params.search = `%${_search}%`
        params.search_fields = [
          'fromMail',
          'createdBy-firstName',
          'createdBy-lastName',
          'toAddresses-mail',
          'subject',
          'contentHtml',
        ].join(',')
      }

      return axiosIns.get('1/mails', { params })
        .then(res => resolve({
          data: res.data.data,
          items: res.data.data.items,
        }))
        .catch(err => reject(err))
    }),
    GET_GLOBAL_THREAD_EMAILS: (_, {
      filters,
      sorter,
      searchQuery,
      pagination,
    }) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = 'GLOBAL_EMAILS_THREADS'

      if (filters) {
        if (filters.status && filters.status !== emailStatuses.ALL) params['eq_mails-mailboxFolder'] = filters.status
        if (filters.selectedEmail) params['eq_mailLast-userMailbox'] = filters.selectedEmail
      }

      if (sorter) {
        const sortDir = sorter?.sortDesc ? 'desc' : 'asc'
        const sortBy = 'mailLast-deliveredAt' || 'id'
        params.orderBy = `${sortBy}.${sortDir}`
      }

      if (pagination) {
        params.page = pagination.page
        params.limit = pagination.perPage
      }

      if (searchQuery && searchQuery.length) {
        // eslint-disable-next-line no-underscore-dangle
        const _search = searchQuery.replaceAll(' ', '%')
        params.search = `%${_search}%`
        params.search_fields = [
          'mailLast-fromMail',
          'mailLast-createdBy-firstName',
          'mailLast-createdBy-lastName',
          'mailLast-toAddresses-mail',
          'mailLast-subject',
          'mailLast-contentHtml',
        ].join(',')
      }

      return axiosIns.get('1/mailThreads', { params })
        .then(res => resolve({
          data: res.data.data,
          items: res.data.data.items,
        }))
        .catch(err => reject(err))
    }),

    [GET_GLOBAL_EMAIL]: (_, id) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = 'GLOBAL_EMAILS'

      return axiosIns.get(`1/mails/${id}`, { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),

    [SEND_EMAIL]: (_, { id, mail, mailId }) => (mailId ? axiosIns.patch(`1/userMailboxes/${id}/mails`, mail, config) : axiosIns.post(`1/userMailboxes/${id}/mails`, mail, config)),

    [TEST_GLOBAL_EMAILS]: (_, level = 0) => axiosIns.get('1/mails', {
      params: {
        fields_info: level,
      },
    })
      .then(res => res.data.data),
  },
}

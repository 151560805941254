import Vue from 'vue'

// axios
import axios from 'axios'
/* eslint-disable */
import router from '@/router'
import { showAlert } from '@core/mixins/ui/alert'
// eslint-disable-next-line import/no-cycle
import { showToast } from '@core/mixins/ui/toast'
import i18n from '@/libs/i18n'
import ClientWebsocketService from '@/helpers/websocket'

const urls = {
  // eslint-disable-next-line no-nested-ternary
  root: window.location.port || window.location.origin.toString() === 'https://demo.saleswizardapp.com' ? 'https://d1.saleswizardapp.com' : window.location.origin.toString() === 'https://d2.saleswizardapp.com' ? 'https://d2.saleswizardapp.com' : window.location.origin,
  oze_test: 'https://testowy05.saleswizard.pl',
  oze_crm: 'https://crm.oze-centrum.pl',
  solaris: 'https://solaris.saleswizard.pl',
  hydropure_test: 'https://hydropure-test.saleswizard.pl',
  hydropure_crm: 'https://crm.hydropure.com.pl',
  bsystems: 'https://4bsystems.saleswizard.pl',
  campter: 'https://campter.saleswizard.pl',
  testy: 'https://testy.saleswizard.pl',
  nexenergy: 'https://crm.nexenergy.pl',
  gimostrowo: 'https://gimostrowo.saleswizard.pl',
  kronen: 'https://crm.lasland.pl',
  kogen: 'https://crm.kogen.com.pl',
  rudnicki: 'https://rudnicki.saleswizard.pl',
  testowy: 'https://test.saleswizard.pl',
  sport: 'https://k1sport.saleswizard.pl',
  adv: 'https://adv.saleswizard.pl',
  interkadra: 'https://inter-kadra.saleswizard.pl',
  dux: 'https://crm.dux.net.pl',
  eminus: 'https://crm.eminus.com.pl',
  lidl: 'https://gdpagency.saleswizard.pl',
  infoski: 'https://infoski.saleswizard.pl',
  drukserwis: 'https://drukserwis.saleswizard.pl',
  sid: 'https://1732992183r05134.saleswizardapp.com',
  dlsk: 'https://dlsk.saleswizard.pl',
  prostycrm: 'https://prostycrm.saleswizard.pl',
  funzeum: 'https://funzeum.saleswizard.pl',
  emimarcrm: 'https://emimarcrm.saleswizard.pl',
  sparkz: 'https://crm.sparkz.com.pl',
  newDemo: 'https://new-demo.saleswizard.pl',
}
// 0a552d4e-0967-4946-959a-3afc5e04848b
// 0a552d4e-0967-4946-959a-3afc5e04848b
/* eslint-disable */
let baseUrl = urls.root

// ['localhost', '192.', '172.'].forEach(domainFragment => {
//   if (window.location.href.includes(domainFragment)) {
//     baseUrl = urls.root
//   }
// })

// Cache adapter
import { setupCache, serializeQuery } from 'axios-cache-adapter'
import md5 from "uuid/dist/esm-node/md5";
import {discordWebhook} from "@/helpers/discordWebhook";

const { adapter: axiosCacheAdapter, cache } = setupCache({
  // debug: false,
  debug: process.env.NODE_ENV !== 'production',
  exclude: {
    query: false
  },
  maxAge: 100
})
const { adapter: noCacheAdapter } = setupCache({ ignoreCache: true });

const runningRequests = {}
const noDuplicateRequestsAdapter = request => {
  if (request.method !== 'GET' || request.url.includes('/me')) return noCacheAdapter(request)

  const requestUrl = `${request.baseURL ? request.baseURL : ''}${request.url}`
  let requestKey = requestUrl + serializeQuery(request)

  if (request.data) requestKey = requestKey + md5(Object.keys(request?.data || {}).join('_') || '')

  // Add the request to runningRequests
  if (!runningRequests[requestKey]) runningRequests[requestKey] = axiosCacheAdapter(request)

  // Return the response promise
  return runningRequests[requestKey].finally(() => {
    // Finally, delete the request from the runningRequests whether there's error or not
    delete runningRequests[requestKey]
  })
}

const axiosIns = axios.create({
  adapter: noDuplicateRequestsAdapter,
  baseURL: `${baseUrl}/api/v1/user`,
  headers: {},
})

axiosIns.cache = cache

// Uncomment on production and change Version.txt
let showed = false
let source

const getRequest = (req) => {
  const reqCp = { ...req }

  if (localStorage.getItem('token')) {
    reqCp.headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  }

  if (window.location.href.includes('https:')) {
    reqCp.headers.SwVersion = '03.12.2024/20:00'
  }

  if (req?.dev) {
    return { ...reqCp, baseURL: req.baseURL.replaceAll('/api/v1/user', '/api/v1/dev') }
  }

  if (req?.noApi || (req.url.includes('storage') && !req.url.includes('storages'))) {
    return { ...reqCp, baseURL: req.baseURL.replaceAll('/api/v1/user', '') }
  }

  if (reqCp.url.includes('undefined') || reqCp.url.includes('null')) {
    discordWebhook().pushInfoMessage('AXIOS -> Undefined or Null params found')
    throw new axios.Cancel('Operation canceled by the system. Undefined or Null params found -> ' + reqCp.url);
  }

  // Clear request body data
  // if (reqCp?.data) {
  //   const payload = typeof reqCp.data === 'string' ? JSON.parse(reqCp.data) : reqCp.data
  //
  //   Object.keys(payload).map(key => {
  //     if (Array.isArray(payload[key])) {
  //       payload[key] = payload[key].filter(Boolean)
  //     }
  //   })
  //
  //   reqCp.data = JSON.stringify(payload)
  // }

  return reqCp
}

axiosIns.interceptors.request.use(async req => {
  return getRequest(req)
}, err => err)

if (window.location.href.includes('https')) {
  let current = 0

  axiosIns.interceptors.request.use(async req => {
    return getRequest(req)
  }, async err => {
    if (window.location.href.includes('ai') || window.location.href.includes('google')) {
      throw err
      return err
    }

    if (typeof source !== typeof undefined) source.cancel('Operation canceled due to new request.')
    source = axios.CancelToken.source()
    if (err.response?.data?.dev?.logId && err?.response?.data?.message !== 'SYSTEM_NEWER_VERSION_AVAILABLE' && current < 25) {
      const logId = err.response?.data?.dev?.logId
      const payload = {
        currentUrl: window.location.href,
      }

      await axiosIns.post(`api/public/1/errorReport/${logId}`, payload, { cancelToken: source.token })

      // eslint-disable-next-line no-plusplus
      current++
    }
    if ([
      'TOKEN_NOT_FOUND',
      'TOKEN_INVALID',
      'TOKEN_EXPIRED',
      'TOKEN_NO_LONGER_VALID',
    ].includes(err?.response?.data?.message) && !window.location.href.includes('login') && !window.location.href.includes('google') && !window.location.href.includes('ai') && localStorage.getItem('token')) {
      try {
        await axiosIns.post('1/auth/logout')
      } finally {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        await router.push('/login')
      }
    } else {
      discordWebhook().pushWarningMessage('API ERROR -> ' + err?.response?.data?.message)
    }

    throw err
  })
}

/* eslint-disable */
axiosIns.interceptors.response.use(async res => {
  // if (!res.data?.data) {
  //   throw {
  //     response: {
  //       data: {
  //         message: 'INVALID_API_RESPONSE'
  //       }
  //     }
  //   }
  // }
  return res
}, async err => {
  if (window.location.href.includes('ai') || window.location.href.includes('google')) {
    throw err
    return err
  }
  if (err?.response?.data?.code === 403 && err?.response?.data?.message === 'SYSTEM_BLOCKED' && router.currentRoute.name !== 'system-blocked') {
    await router.push({ name: 'system-blocked' })

    throw err
  }
  if (err?.response?.data?.code === 402 && !showed) {
    showed = true
    await showAlert('error', '', i18n.t('PaymentRequiredInfo'), false, i18n.t('GoToSubscriptionPanel'))
        .then(() => {
          window.open('https://panel.4b-systems.com/', '_blank')
        })

    setTimeout(() => { showed = false }, 4000)
    throw err
  }

  if (err?.response?.data?.code === 500 && err?.response?.data?.dev.shortMessages[0].includes('Data too long for field')) {
    showed = true

    await showToast('danger', i18n.t('errors.FIELD_MAX_LENGTH'))

    setTimeout(() => { showed = false }, 4000)
    throw err
  }
  if (typeof source !== typeof undefined) source.cancel('Operation canceled due to new request.')

  source = axios.CancelToken.source()

  if ([
    'TOKEN_NOT_FOUND',
    'TOKEN_INVALID',
    'TOKEN_EXPIRED',
    'TOKEN_NO_LONGER_VALID',
  ].includes(err?.response?.data?.message) && !window.location.href.includes('login') && !window.location.href.includes('google') && !window.location.href.includes('ai') && localStorage.getItem('token')) {
    try {
      await localStorage.removeItem('token')
      await localStorage.removeItem('user')

      ClientWebsocketService.instances.forEach(instance => {
        instance?.closeAndDelete()
      })
      axiosIns.post('1/auth/logout').catch(() => {})
    } finally {
      router.push('/login')
    }
  }

  throw err
})

Vue.prototype.$http = axiosIns
Vue.prototype.axiosBaseUrl = baseUrl

export default axiosIns

/*
https://oh-vue-icons.js.org/docs
 */

import Vue from 'vue'

import { OhVueIcon, addIcons } from 'oh-vue-icons'
import * as BooststrapIcon from 'oh-vue-icons/icons/bi'
import * as HeroIcon from 'oh-vue-icons/icons/hi'
import * as IonIcon from 'oh-vue-icons/icons/io'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {
  faMagicWandSparkles,
  faCoffee,
  faSort,
  faHeart,
  faAlignCenter,
  faWindowRestore,
} from '@fortawesome/free-solid-svg-icons'

// HeroIcon icons
// https://heroicons.com/
addIcons(...Object.values(HeroIcon))

// Bootstrap icons
// https://icons.getbootstrap.com/
addIcons(...Object.values(BooststrapIcon))

// Ion icons
// https://ionic.io/ionicons
addIcons(...Object.values(IonIcon))

/* add icons to the library */
library.add(faMagicWandSparkles)
library.add(faCoffee)
library.add(faSort)
library.add(faHeart)
library.add(faAlignCenter)
library.add(faWindowRestore)

Vue.component('VIcon', OhVueIcon)

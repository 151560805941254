<template>
  <b-form @submit.prevent="validationForm">
    <div v-if="!isModalView">
      <b-button
        variant="warning"
        size="sm"
        class="mb-1 mr-50"
        @click="isCreateMode ? $router.push({ name: 'contacts' }) : $router.go(-1)"
      >
        <feather-icon icon="ArrowLeftIcon" />
        {{ $t('Return') }}
      </b-button>

      <b-button
        type="submit"
        variant="primary"
        size="sm"
        class="mb-1 mr-50"
        :disabled="loading"
      >
        {{ $t('Save') }}
      </b-button>
    </div>

    <validation-observer ref="newContactForm">
      <b-row v-if="!$route.name.includes('organization')">
        <b-col sm="6">
          <b-card :class="{ 'border-primary': !isCompany }"
                  style="height: 60px"
                  no-body
                  class="border cursor-pointer"
                  @click="isCompany = false"
          >
            <div class="d-flex justify-content-between px-1 align-items-center h-100">
              <div class="d-flex align-items-center">
                <b-form-radio v-model="isCompany"
                              :value="false"
                />
                <sw-icon icon="UserIcon"
                         class="mr-25"
                />
                {{ $t('Person') }}
              </div>
            </div>
          </b-card>

        </b-col>
        <b-col sm="6">
          <b-card :class="{ 'border-primary': isCompany }"
                  style="height: 60px"
                  no-body
                  class="border cursor-pointer"
                  @click="isCompany = true"
          >
            <div class="d-flex justify-content-between px-1 align-items-center h-100">
              <div class="d-flex align-items-center">
                <b-form-radio v-model="isCompany"
                              :value="true"
                />
                <sw-icon icon="GlobeIcon"
                         class="mr-25"
                />
                {{ $t('Company') }}
              </div>

              <div class="d-flex align-items-center">
                <sw-select class="mb-0 pb-0">
                  <v-select
                    v-model="contact.organization"
                    :options="organizations"
                    :placeholder="$t('Select')"
                    label="name"
                    class="mb-0 pb-0 select-size-sm mr-25"
                    :disabled="contact.mainOrganizationContact"
                    style="width: 250px"
                    :filterable="false"
                    @search="reloadOrganizations"
                    @input="changeOrganization"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>
                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>
                </sw-select>
                <b-button
                  :disabled="isModalView && $route.name !== 'shop/checkout'"
                  variant="primary"
                  size="sm"
                  @click="createCompany(false)"
                >
                  <sw-icon icon="PlusIcon" />
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-alert show="areOrganizationFieldsRequired"
               variant="danger"
      >
        <div>{{ $t('InAgreementSomeOrganizationFieldsAreRequired') }}</div>
      </b-alert>
      <!--      <b-alert-->
      <!--        v-if="!$route.name.includes('organization')"-->
      <!--        variant="primary"-->
      <!--        show-->
      <!--      >-->
      <!--        <div-->
      <!--          v-if="contact.mainOrganizationContact"-->
      <!--          class="alert-heading"-->
      <!--        >-->
      <!--          {{ $t('MainOrganizationContact') }}: <span class="font-weight-bold">{{ contact.organization.name }}</span>-->
      <!--        </div>-->

      <!--        <div class="alert-body d-flex align-items-center font-weight-bold p-50">-->

      <!--          <b-form-checkbox-->
      <!--            v-model="isCompany"-->
      <!--            class="custom-control-primary mx-50"-->
      <!--            :disabled="contact.mainOrganizationContact"-->
      <!--            switch-->
      <!--          />-->
      <!--          <div v-if="isCompany">-->
      <!--            <div class="d-flex align-items-center">-->
      <!--              <div class="mr-25">-->
      <!--                {{ $t('AssignToCompany') }}-->
      <!--              </div>-->
      <!--              <div class="mr-25">-->
      <!--                <sw-select>-->
      <!--                  <v-select-->
      <!--                    v-model="contact.organization"-->
      <!--                    :options="organizations"-->
      <!--                    :placeholder="$t('Select')"-->
      <!--                    label="name"-->
      <!--                    :disabled="contact.mainOrganizationContact"-->
      <!--                    style="width: 250px"-->
      <!--                    :filterable="false"-->
      <!--                    @search="reloadOrganizations"-->
      <!--                    @input="changeOrganization"-->
      <!--                  >-->
      <!--                    <template #no-options="{ search }">-->
      <!--                      <span v-if="search.length">-->
      <!--                        {{ $t('NoData') }}-->
      <!--                      </span>-->
      <!--                      <span v-else>-->
      <!--                        {{ $t('TypeToSearch') }}-->
      <!--                      </span>-->
      <!--                    </template>-->
      <!--                  </v-select>-->
      <!--                </sw-select>-->
      <!--              </div>-->
      <!--              <div-->
      <!--                v-if="!contact.organization && !$route.name.includes('organization')"-->
      <!--                class="mx-25"-->
      <!--              >{{ $t('Or') }}</div>-->
      <!--              <div-->
      <!--                v-if="!$route.name.includes('organization')"-->
      <!--                class="ml-25"-->
      <!--              >-->
      <!--                <b-button-->
      <!--                  v-if="!contact.organization"-->
      <!--                  :disabled="isModalView && $route.name !== 'shop/checkout'"-->
      <!--                  variant="primary"-->
      <!--                  size="sm"-->
      <!--                  @click="createCompany(false)"-->
      <!--                >-->
      <!--                  {{ $t('CreateCompany') }}-->
      <!--                </b-button>-->
      <!--                &lt;!&ndash;                    <b-button&ndash;&gt;-->
      <!--                &lt;!&ndash;                      v-if="contact.organization"&ndash;&gt;-->
      <!--                &lt;!&ndash;                      variant="warning"&ndash;&gt;-->
      <!--                &lt;!&ndash;                      class="ml-50"&ndash;&gt;-->
      <!--                &lt;!&ndash;                      size="sm"&ndash;&gt;-->
      <!--                &lt;!&ndash;                      @click="addOrganization(true)"&ndash;&gt;-->
      <!--                &lt;!&ndash;                    >&ndash;&gt;-->
      <!--                &lt;!&ndash;                      {{ $t('Edit') }}&ndash;&gt;-->
      <!--                &lt;!&ndash;                    </b-button>&ndash;&gt;-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div v-else>-->
      <!--            {{ $t('TypeP') }}-->
      <!--            <div>-->
      <!--              <small-->
      <!--                v-if="areOrganizationFieldsRequired"-->
      <!--                class="text-danger"-->
      <!--              >{{ $t('InAgreementSomeOrganizationFieldsAreRequired') }}</small>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </b-alert>-->

      <div>
        <b-overlay
          :show="loading"
          no-wrap
          spinner-variant="primary"
        />
        <b-card :title="$t('BasicInformation')">
          <b-row>
            <!-- Name -->
            <b-col
              md="4"
              lg="4"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact.firstName')"
                :rules="requiredFields.length ? isRequired('firstName') : 'required'"
              >
                <b-form-group :label="$t('contact.firstName')">
                  <b-form-input
                    v-model="contact.firstName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Last name -->
            <b-col
              md="4"
              lg="4"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact.lastName')"
                :rules="requiredFields.length ? isRequired('lastName') : ''"
              >
                <b-form-group :label="$t('contact.lastName')">
                  <b-form-input
                    v-model="contact.lastName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Last name -->
            <b-col
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_POSITION])"
              md="4"
              lg="4"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('ContactPosition')"
                :rules="requiredFields.length ? isRequired('position') : ''"
              >
                <sw-select
                  :name="$t('ContactPosition')"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="contact.position"
                    :options="contactPositionTypes"
                    :reduce="item => item.value"
                  >
                    <template #option="{ label }">
                      {{ $t(label) }}
                    </template>
                    <template #selected-option="{ label }">
                      {{ $t(label) }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </sw-select>
              </validation-provider>
            </b-col>

            <b-col
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_TAG]) && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_TAGS])"
              md="4"
            >
              <validation-provider
                :name="$t('Tags')"
              >
                <sw-select :name="$t('Tags')"
                           :tip="checkRequiredPermissions([$roles.SETTINGS]) ? $t('UncreatedTagsWillBeCreated') : ''"
                >
                  <v-select
                    v-model="contact.contactTypes"
                    multiple
                    :taggable="checkRequiredPermissions([$roles.SETTINGS])"
                    :push-tags="checkRequiredPermissions([$roles.SETTINGS])"
                    :options="tags"
                    label="name"
                  >
                    <template #no-options>
                      {{ $t('NoOptions') }}
                    </template>
                    <template #option="{ id, name, color }">
                      <div class="d-flex align-items-center"
                           style="padding-bottom: 2px"
                      >
                        <div
                          v-if="id"
                          class="color-dot"
                          style="border: 1px solid white"
                          :style="{ backgroundColor: color }"
                        />
                        <div v-else
                             class="mr-50"
                        >
                          <b-badge variant="success">
                            {{ $t('New') }}
                          </b-badge>
                        </div>
                        {{ name }}
                      </div>
                    </template>
                    <template #selected-option="{ id, name, color }">
                      <div class="d-flex align-items-center"
                           style="padding-bottom: 2px"
                      >
                        <div
                          v-if="id"
                          class="color-dot"
                          style="border: 1px solid white"
                          :style="{ backgroundColor: color }"
                        />
                        <div v-else
                             class="mr-50"
                        >
                          <b-badge variant="light"
                                   class="text-primary"
                          >
                            {{ $t('New') }}
                          </b-badge>
                        </div>
                        {{ name }}
                      </div>
                    </template>
                  </v-select>
                </sw-select>
              </validation-provider>
            </b-col>

            <!-- Contact Status -->
            <b-col md="4">
              <sw-select :name="$t('contact.contactStatus')">
                <v-select
                  v-model="contact.status"
                  :options="statuses"
                  label="name"
                  :clearable="false"
                  :reduce="status => status.id"
                >
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                  <template #option="{ name, color }">
                    <div
                      class="color-dot"
                      :style="{ backgroundColor: color }"
                    />
                    {{ name }}
                  </template>
                  <template #selected-option="{ name, color }">
                    <div
                      class="color-dot"
                      :style="{ backgroundColor: color }"
                    />
                    {{ name }}
                  </template>
                </v-select>
              </sw-select>
            </b-col>

            <!-- Group -->
            <b-col
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_GROUP])"
              md="4"
            >
              <sw-select :name="$t('contact.group')">
                <v-select
                  v-model="contact.userGroups"
                  :options="groups"
                  label="name"
                  :reduce="group => group.id"
                  multiple
                >
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                  <template #option="{ name, color }">
                    <div
                      class="color-dot"
                      style="border: 1px solid white"
                      :style="{ backgroundColor: color }"
                    />
                    {{ name }}
                  </template>
                  <template #selected-option="{ name, color }">
                    <div
                      class="color-dot"
                      style="border: 1px solid white"
                      :style="{ backgroundColor: color }"
                    />
                    {{ name }}
                  </template>
                </v-select>
              </sw-select>
            </b-col>

            <!-- E-mail -->
            <b-col
              md="6"
              sm="12"
              class="mt-1"
            >
              <validation-provider
                v-for="(email, index) in contact.mailAddresses"
                v-slot="{ errors }"
                :key="`email_address_${index}`"
                :name="`${index + 1}.${$t('contact.email')}`"
                :rules="requiredFields.length ? isRequired('email') : ''"
              >
                <span>
                  {{ index + 1 }}. {{ $t('contact.email') }}
                  <feather-icon
                    v-if="index === 0"
                    v-b-tooltip
                    icon="AlertCircleIcon"
                    class="text-primary ml-25"
                    :title="$t('FirstMailIsPrimary')"
                  />
                </span>

                <b-input-group>
                  <b-form-input
                    v-model.trim="contact.mailAddresses[index]"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    @blur="checkExistedEmails"
                  />
                  <b-input-group-append v-if="index !== 0 || existedMail(email)">
                    <!-- Delete Email Buttons -->
                    <b-button
                      v-if="index !== 0"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="$delete(contact.mailAddresses, index)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="12"
                      />
                    </b-button>
                    <!-- Email Exist Buttons -->
                    <b-button
                      v-if="existedMail(email)"
                      :id="`email_exist_button_${index}`"
                      variant="outline-warning"
                    >
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="12"
                      />
                      <b-popover
                        :target="`email_exist_button_${index}`"
                        triggers="hover"
                      >
                        <template #title>
                          <span>{{ $t('TypedEmailExist') }}</span>
                        </template>
                        <div
                          v-for="existedContact in existedMail(email).contacts"
                          :key="`contact_${index}_${existedContact.id}`"
                        >
                          <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                            {{ existedContact.firstName }} {{ existedContact.lastName }}
                          </router-link>
                        </div>
                        <!--                        test@mail-saleswizardapp.com-->
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <b-button
                size="sm"
                class="mt-25"
                variant="primary"
                @click="contact.mailAddresses.push('')"
              >
                <feather-icon icon="PlusIcon" />
                {{ $t('Add') }}
              </b-button>
            </b-col>

            <!-- phone -->
            <b-col
              md="6"
              sm="12"
              class="mt-1"
            >
              <validation-provider
                v-for="(phone, index) in contact.phoneNumbers"
                v-slot="{ errors }"
                :key="`phone_${index}`"
                :name="`${index + 1}.${$t('contact.phone')}`"
                :rules="requiredFields.length ? isRequired('phone') : ''"
              >
                <span
                  class="mt-1"
                >{{ index + 1 }}. {{ $t('contact.phone') }} <feather-icon
                  v-if="index === 0"
                  v-b-tooltip
                  icon="AlertCircleIcon"
                  class="text-primary ml-25"
                  :title="$t('FirstPhoneIsPrimary')"
                /></span>
                <b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-input-group>
                <b-input-group>
                  <b-input-group-prepend>
                    <sw-select>
                      <v-select
                        v-model="contact.phoneNumbers[index].countryCode"
                        :options="countryCodes"
                        label="dial_code"
                        style="min-width: 125px"
                        class="mr-50"
                        :clearable="false"
                        @option:selected="checkExistedPhones"
                      />
                    </sw-select>
                  </b-input-group-prepend>
                  <cleave
                    v-model="contact.phoneNumbers[index].value"
                    :options="cleaveOptions.number"
                    class="form-control rounded"
                    :state="errors.length > 0 ? false:null"
                    @blur="checkExistedPhones"
                  />
                  <b-input-group-append v-if="index !== 0 || existedPhone(phone)">
                    <b-button
                      v-if="index !== 0"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="$delete(contact.phoneNumbers, index)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="12"
                      />
                    </b-button>
                    <!-- Email Exist Buttons -->
                    <b-button
                      v-if="existedPhone(phone)"
                      :id="`phone_exist_button_${index}`"
                      variant="outline-warning"
                    >
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="12"
                      />
                      <b-popover
                        :target="`phone_exist_button_${index}`"
                        triggers="hover"
                      >
                        <template #title>
                          <span>{{ $t('TypedPhoneExist') }}</span>
                        </template>
                        <div
                          v-for="existedContact in existedPhone(phone).contacts"
                          :key="`contact_phone_${index}_${existedContact.id}`"
                        >
                          <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                            {{ existedContact.firstName }} {{ existedContact.lastName }}
                          </router-link>
                        </div>
                        <!--                        test@mail-saleswizardapp.com-->
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <b-button
                size="sm"
                class="mt-25"
                variant="primary"
                @click="contact.phoneNumbers.push({
                  value: '',
                  countryCode: {
                    code: 'PL',
                    dial_code: '+48 ',
                    name: 'Poland',
                  },
                })"
              >
                <feather-icon icon="PlusIcon" />
                {{ $t('Add') }}
              </b-button>
            </b-col>

            <!-- Users -->
            <b-col
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ASSIGNED_USERS]) && checkRequiredPermissions([$roles.CONTACT_EDIT_ASSIGNED_USERS])"
              class="mt-1"
              cols="12"
            >
              <sw-select :name="$t('contact.users')">
                <assigned-users
                  v-show="checkRequiredPermissions([$roles.CONTACT_EDIT_ASSIGNED_USERS])"
                  :value="assignedUsers"
                  @input="changeAssignedUsers"
                />
              </sw-select>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <!-- Organization -->
      <component :is="'b-card'"
                 :title="$t('Addresses')"
      >
        <b-overlay
          :show="loading"
          no-wrap
          spinner-variant="primary"
        />

        <b-row>
          <!-- Natural Person -->
          <b-col cols="12">
            <!-- Address -->
            <b-tabs>
              <b-tab :title="$t('PrimaryAddress')">
                <b-row>
                  <b-col
                    v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ADDRESS_COUNTRY])"
                    md="6"
                    lg="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Country')"
                      :rules="requiredFields.length ? isRequired('addressCountry') : ''"
                    >
                      <b-form-group :label="$t('Country')">
                        <b-form-input
                          id="basicInput"
                          v-model="contact.addressCountry"
                          :placeholder="$t('Country')"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ADDRESS_CITY])"
                    md="6"
                    lg="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('City')"
                      :rules="requiredFields.length ? isRequired('addressCity') : ''"
                    >
                      <b-form-group :label="$t('City')">
                        <b-form-input
                          id="basicInput"
                          v-model="contact.addressCity"
                          :placeholder="$t('City')"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ADDRESS_POSTAL_CODE])"
                    md="6"
                    lg="4"
                  >

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('contact.postalCode')"
                      :rules="requiredFields.length ? isRequired('addressPostalCode') : ''"
                    >
                      <b-form-group
                        :label="$t('contact.postalCode')"
                        :state="errors.length > 0 ? false:null"
                      >
                        <cleave
                          id="basicInput"
                          v-model="contact.addressPostalCode"
                          :placeholder="$t('contact.postalCode')"
                          :options="cleaveOptions.zip"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ADDRESS_STREET])"
                         md="6"
                  >

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Street')"
                      :rules="requiredFields.length ? isRequired('addressStreet') : ''"
                    >
                      <b-form-group :label="$t('Street')">
                        <b-form-input
                          id="basicInput"
                          v-model="contact.addressStreet"
                          :placeholder="$t('Street')"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ADDRESS_NUMBER])"
                         md="6"
                  >

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('BuildingNumber')"
                      :rules="requiredFields.length ? isRequired('addressNumber') : ''"
                    >
                      <b-form-group :label="$t('BuildingNumber')">
                        <b-form-input
                          id="basicInput"
                          v-model="contact.addressNumber"
                          :placeholder="$t('BuildingNumber')"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-tab>

              <!-- Correspondence -->
              <b-tab :title="$t('AddressForCorrespondence')">
                <b-button
                  size="sm"
                  variant="primary"
                  style="margin-top: -5px"
                  class="mb-1"
                  @click="copyMainAddress"
                >
                  {{ $t('Copy') }} (<span class="text-lowercase">{{ $t('PrimaryAddress') }}</span>)
                </b-button>
                <b-row>
                  <b-col
                    v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ADDRESS_COUNTRY])"
                    md="6"
                    lg="4"
                  >

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('AddressForCorrespondence') + ': ' + $t('Country').toLowerCase()"
                      :rules="requiredFields.length ? isRequired('addressCorrespondenceCountry') : ''"
                    >
                      <b-form-group :label="$t('Country')">
                        <b-form-input
                          id="basicInput"
                          v-model="contact.addressCorrespondenceCountry"
                          :placeholder="$t('Country')"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ADDRESS_CITY])"
                    md="6"
                    lg="4"
                  >

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('AddressForCorrespondence') + ': ' + $t('City').toLowerCase()"
                      :rules="requiredFields.length ? isRequired('addressCorrespondenceCity') : ''"
                    >
                      <b-form-group :label="$t('City')">
                        <b-form-input
                          id="basicInput"
                          v-model="contact.addressCorrespondenceCity"
                          :placeholder="$t('City')"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ADDRESS_POSTAL_CODE])"
                    md="6"
                    lg="4"
                  >

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('AddressForCorrespondence') + ': ' + $t('contact.postalCode').toLowerCase()"
                      :rules="requiredFields.length ? isRequired('addressCorrespondencePostalCode') : ''"
                    >
                      <b-form-group
                        :label="$t('contact.postalCode')"
                        :state="errors.length > 0 ? false:null"
                      >
                        <cleave
                          id="basicInput"
                          v-model="contact.addressCorrespondencePostalCode"
                          :placeholder="$t('contact.postalCode')"
                          :options="cleaveOptions.zip"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ADDRESS_STREET])"
                         md="6"
                  >

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('AddressForCorrespondence') + ': ' + $t('Street').toLowerCase()"
                      :rules="requiredFields.length ? isRequired('addressCorrespondenceStreet') : ''"
                    >
                      <b-form-group :label="$t('Street')">
                        <b-form-input
                          id="basicInput"
                          v-model="contact.addressCorrespondenceStreet"
                          :placeholder="$t('Street')"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_ADDRESS_NUMBER])"
                         md="6"
                  >

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('AddressForCorrespondence') + ': ' + $t('BuildingNumber').toLowerCase()"
                      :rules="requiredFields.length ? isRequired('addressCorrespondenceNumber') : ''"
                    >
                      <b-form-group :label="$t('BuildingNumber')">
                        <b-form-input
                          id="basicInput"
                          v-model="contact.addressCorrespondenceNumber"
                          :placeholder="$t('BuildingNumber')"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>

        <!-- Deliver Addresses -->
        <delivery-addresses
          v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_DELIVERY_ADDRESSES])"
          ref="contact-delivery-addresses"
          :contact="contact"
          :addresses="contact.deliveryAddresses"
          @edit-address="editAddress"
          @remove-address="deleteAddress"
        />
      </component>

      <!-- Agreement Additional data -->
      <b-card
        v-if="checkRequiredModule('agreementModule') && checkRequiredViewPermissions([
          $viewRanks.SHOW_CONTACT_FORM_PERSONAL_ID,
          $viewRanks.SHOW_CONTACT_FORM_BIRTH_DATE,
          $viewRanks.SHOW_CONTACT_FORM_FAMILY_NAME,
          $viewRanks.SHOW_CONTACT_FORM_MATERIAL_STATUS,
          $viewRanks.SHOW_CONTACT_FORM_FINANCIAL_POSITION,
          $viewRanks.SHOW_CONTACT_FORM_PERSONAL_DOCUMENTS,
        ])"
        :title="$t('AdditionalAgreementInformation')"
      >
        <b-overlay
          :show="loading"
          no-wrap
          spinner-variant="primary"
        />

        <b-row>
          <!-- CONTACT :: Personal Id -->
          <b-col
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_PERSONAL_ID])"
            sm="12"
            md="6"
            lg="3"
          >

            <validation-provider
              v-slot="{ errors }"
              :name="$t('Pesel')"
              :rules="requiredFields.length ? isRequired('personalId') : ''"
            >
              <b-form-group :label="$t('Pesel')">
                <b-form-input
                  id="basicInput"
                  v-model="contact.personalId"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- CONTACT :: Born Date -->
          <b-col
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_BIRTH_DATE])"
            sm="12"
            md="6"
            lg="3"
          >

            <validation-provider
              v-slot="{ errors }"
              :name="$t('contact.birthDate')"
              :rules="requiredFields.length ? isRequired('birthDate') : ''"
            >
              <sw-select
                :name="$t('contact.birthDate')"
                :state="errors.length > 0 ? false:null"
              >
                <sw-select-fpr
                  :is-empty="contact.birthDate"
                  @clear="contact.birthDate = ''"
                >
                  <flat-pickr
                    id="releaseDate"
                    v-model="contact.birthDate"
                    :config="{ locale: getCalendarLocale($i18n.locale) }"
                    class="form-control"
                    :state="errors.length > 0 ? false:null"
                  />
                </sw-select-fpr>
              </sw-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- CONTACT :: Family Name -->
          <b-col
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_FAMILY_NAME])"
            sm="12"
            md="6"
            lg="3"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('contact.familyName')"
              :rules="requiredFields.length ? isRequired('familyName') : ''"
            >
              <b-form-group :label="$t('contact.familyName')">
                <b-form-input
                  id="basicInput"
                  v-model="contact.familyName"
                  :state="errors.length > 0 ? false:null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- CONTACT :: Civil Status -->
          <b-col
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_MATERIAL_STATUS])"
            md="6"
            lg="3"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('contact.maritalStatus')"
              :rules="requiredFields.length ? isRequired('maritalStatus') : ''"
            >
              <sw-select
                :name="$t('contact.maritalStatus')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="contact.maritalStatus"
                  :options="civilStatus"
                  :state="errors.length > 0 ? false:null"
                >
                  <template #selected-option="{ label }">
                    {{ $t(`contact.${label}`) }}
                  </template>
                  <template #option="{ label }">
                    {{ $t(`contact.${label}`) }}
                  </template>
                </v-select>
              </sw-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- CONTACT :: Property Status -->
          <b-col
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_FINANCIAL_POSITION])"
            sm="12"
            md="6"
            lg="3"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('contact.financialPosition')"
              :rules="requiredFields.length ? isRequired('financialPosition') : ''"
            >
              <sw-select
                :name="$t('contact.financialPosition')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="contact.financialPosition"
                  :options="propertyStatus"
                  :state="errors.length > 0 ? false:null"
                >
                  <template #selected-option="{ label }">
                    {{ $t(`contact.${label}`) }}
                  </template>
                  <template #option="{ label }">
                    {{ $t(`contact.${label}`) }}
                  </template>
                </v-select>
              </sw-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- CONTACT :: Additional Documents -->
          <b-col
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_PERSONAL_DOCUMENTS])"
            sm="12"
          >
            <div>
              {{ $t('contact.PersonIdentityDocuments') }}

              <feather-icon
                v-b-tooltip
                icon="AlertCircleIcon"
                class="ml-50"
                :title="$t('OnlyFirstDocument')"
              />
            </div>

            <b-row
              v-for="(doc, index) in contact.personIdentityDocuments"
              :key="`doc${index}`"
              class="mt-1"
            >
              <b-col
                md="6"
                lg="3"
                class="mb-50"
              >

                <validation-provider
                  v-slot="{ errors }"
                  :name="(index+1) + '. ' + $t('task.Type')"
                  :rules="requiredFields.length ? isRequired('type') : ''"
                >
                  <sw-select :state="errors.length > 0 ? false:null">
                    <v-select
                      v-model="doc.type"
                      :options="documentTypes"
                      :placeholder="$t('task.Type')"
                      style="min-width: 200px"
                      :state="errors.length > 0 ? false:null"
                    >
                      <template #selected-option="{ label }">
                        {{ $t(`contact.${label}`) }}
                      </template>
                      <template #option="{ label }">
                        {{ $t(`contact.${label}`) }}
                      </template>
                    </v-select>
                  </sw-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                lg="3"
                class="mb-50"
              >

                <validation-provider
                  v-slot="{ errors }"
                  :name="(index+1) + '. ' + $t('OrganizationDetails.addressNumber')"
                  :rules="requiredFields.length ? isRequired('number') : ''"
                >
                  <b-form-group>
                    <b-form-input
                      id="basicInput"
                      v-model="doc.number"
                      :placeholder="$t('OrganizationDetails.addressNumber')"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                lg="3"
                class="mb-50"
              >

                <validation-provider
                  v-slot="{ errors }"
                  :name="(index+1) + '. ' + $t('contact.authorityIssuing')"
                  :rules="requiredFields.length ? isRequired('authorityIssuing') : ''"
                >
                  <b-form-group>
                    <b-form-input
                      id="basicInput"
                      v-model="doc.authorityIssuing"
                      :placeholder="$t('contact.authorityIssuing')"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                lg="3"
                class="mb-50"
              >

                <validation-provider
                  v-slot="{ errors }"
                  :name="(index+1) + '. ' + $t('contact.releaseDate')"
                  :rules="requiredFields.length ? isRequired('releaseDate') : ''"
                >
                  <sw-select :state="errors.length > 0 ? false:null">
                    <sw-select-fpr
                      :is-empty="doc.releaseDate"
                      @clear="doc.releaseDate = ''"
                    >
                      <flat-pickr
                        id="releaseDate"
                        v-model="doc.releaseDate"
                        :config="{ locale: getCalendarLocale($i18n.locale) }"
                        class="form-control"
                        :placeholder="$t('contact.releaseDate')"
                        :state="errors.length > 0 ? false:null"
                      />
                    </sw-select-fpr>
                  </sw-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                lg="3"
                class="mb-50"
              >

                <validation-provider
                  v-slot="{ errors }"
                  :name="(index+1) + '. ' + $t('contact.expirationDate')"
                  :rules="requiredFields.length ? isRequired('expirationDate') : ''"
                >
                  <sw-select>
                    <sw-select-fpr
                      :is-empty="doc.expirationDate"
                      @clear="doc.expirationDate = ''"
                    >
                      <flat-pickr
                        id="expirationDate"
                        v-model="doc.expirationDate"
                        :config="{ locale: getCalendarLocale($i18n.locale) }"
                        class="form-control"
                        :placeholder="$t('contact.expirationDate')"
                        :state="errors.length > 0 ? false:null"
                      />
                    </sw-select-fpr>
                  </sw-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                lg="3"
                class="mb-50 text-right text-md-left"
              >
                <b-button
                  variant="outline-danger"
                  class="btn-icon w-100"
                  @click="$delete(contact.personIdentityDocuments, index)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </b-col>
            </b-row>

            <b-button
              variant="primary"
              size="sm"
              class="mt-50"
              @click="contact.personIdentityDocuments.push({ type: '', number: '', authorityIssuing: '', releaseDate: '', expirationDate: ''})"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              {{ $t('Add') }}
            </b-button>
          </b-col>
        </b-row>
        <small
          v-if="areDocumentsFieldsRequired"
          class="text-danger pt-25 mt-25"
        >{{ $t('OneIdDocumentIsRequired') }}</small>
      </b-card>

      <!-- Note Agree -->
      <b-card>
        <b-overlay
          :show="loading"
          no-wrap
          spinner-variant="primary"
        />
        <sw-select
          v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_LANGUAGE])"
          :name="$t('Language')"
        >
          <v-select
            v-model="contact.locale"
            :reduce="item => item.value"
            :options="[
              { value: 'pl_pl', text: 'Polski' },
              { value: 'en_uk', text: 'Angielski' },
            ]"
            label="text"
            item-value="value"
          />
        </sw-select>

        <div v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_NOTE])">
          {{ $t('Note') }}
          <b-form-textarea v-model="contact.note" />
        </div>

        <!--Marketing -->
        <b-form-checkbox
          v-model="contact.agreeMarketing"
          variant="primary"
          class="mt-75"
        >
          {{ $t('contact.marketing') }}
          <sw-icon v-if="checkRequiredModule('campaignModule')"
                   v-b-tooltip
                   class="text-primary"
                   icon="AlertCircleIcon"
                   :title="$t('SelectingAcceptMarketingTip')"
          />
        </b-form-checkbox>
      </b-card>
    </validation-observer>

    <b-alert v-if="requiredFields.length && $refs.newContactForm && $refs.newContactForm.errors && Object.values($refs.newContactForm.errors).filter(e => e.length).length"
             show
             variant="danger"
    >
      <div class="alert-heading">
        {{ $t('RequiredFields') }}
      </div>
      <div class="alert-body">
        <div v-for="(fieldErrorArray, fieldErrorIndex) in Object.values($refs.newContactForm.errors).filter(e => e.length)"
             :key="`${fieldErrorIndex}`"
        >
          {{ fieldErrorArray.join(', ') }}
        </div>
      </div>
    </b-alert>

    <div class="pb-1">
      <b-button
        type="submit"
        variant="primary"
        class="mt-25"
        :disabled="loading"
      >
        {{ $t('Save') }}
      </b-button>
      <b-button
        class="ml-50 mt-25"
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import {
  BAlert, BForm, BFormTextarea, BInputGroupAppend, BPopover, BTab, BTabs, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import * as roles from '@/helpers/permissions'
import { civilStatus, documentTypes, propertyStatus } from '@/@data/contact'
import flatPickr from 'vue-flatpickr-component'
import { countryCodes } from '@/@data/countryCodes'
import Cleave from 'vue-cleave-component'
import axiosIns from '@/libs/axios'
// import PhoneNumber from '@core/components/@sw/PhoneNumber.vue'
import DeliveryAddresses from '@/pages/contact.old/components/DeliveryAddresses.vue'
import { contactPositionTypes } from '@/@types/contactPositionTypes'
import {
  CREATE_CONTACT,
  EDIT_CONTACT,
  GET_CONTACT_STATUSES,
  GET_EDITED_CONTACT,
  GET_GROUPS,
  GET_ORGANIZATIONS,
  GET_PRIVATE_SETTINGS,
  GET_THREAD_TAGS,
  OPEN_MODAL,
} from '../../@constants/mutations'
import AssignedUsers from '../../views/components/AssignedUsers.vue'

export default {
  name: 'CreateContact',
  components: {
    DeliveryAddresses,
    // PhoneNumber,
    Cleave,
    BAlert,
    AssignedUsers,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormTextarea,
    BTabs,
    BTab,
    flatPickr,
    BInputGroupAppend,
    BPopover,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  ...mapGetters({
  }),
  computed: {
    ...mapGetters({
      statuses: 'typeSettings/geContactStatuses',
      reloadContent: 'modal/getReloadContent',
      currentUser: 'auth/getCurrentUser',
    }),
    locale() {
      return this.$i18n.locale
    },
    areDocumentsFieldsRequired() {
      return !!this.requiredFields.filter(field => ['number', 'authorityIssuing', 'releaseDate', 'expirationDate', 'type'].includes(field)).length && !this.contact.personIdentityDocuments.length
    },
    areOrganizationFieldsRequired() {
      return !!this.requiredFields.filter(field => field.includes('organization')).length && !this.isCompany
    },
    isCreateMode() {
      return this.$route.name !== 'edit-contacts' && !this.id
    },
    getCompany() {
      return this.contact.organization
    },
    fullName: {
      get() {
        return `${this.contact.firstName} ${this.contact.lastName}`
      },
      set(value) {
        [this.contact.firstName, this.contact.lastName] = value.split(' ')
      },
    },
  },
  props: {
    isModalView: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    requiredFields: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    contactPositionTypes,
    propertyStatus,
    civilStatus,
    documentTypes,
    roles,
    loading: true,
    isCompany: false,
    organizations: [],
    contact: {
      locale: 'pl_pl',
      mailAddresses: [
        '',
      ],
      lastName: '',
      phoneNumbers: [
        {
          value: '',
          countryCode: {
            code: 'PL',
            dial_code: '+48 ',
            name: 'Poland',
          },
        },
      ],
      deliveryAddresses: [],
      firstName: '',
      position: '',
      status: '',
      addressCity: '',
      addressCountry: '',
      addressNumber: '',
      addressPostalCode: '',
      addressStreet: '',
      addressCorrespondenceCountry: '',
      addressCorrespondenceCity: '',
      addressCorrespondencePostalCode: '',
      addressCorrespondenceStreet: '',
      addressCorrespondenceNumber: '',
      agreeMarketing: false,
      organization: '',
      note: '',
      userGroups: [],
      // Agreement
      personalId: '',
      birthDate: '',
      familyName: '',
      maritalStatus: '',
      financialPosition: '',
      personIdentityDocuments: [

      ],
      contactTypes: [],
      assignedLeadUser: '',
    },
    assignedUsers: [],
    tags: [],
    required,
    options: [],
    groups: [],
    countryCodes,
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        blocks: [3, 3, 3],
      },
      zip: {
        delimiter: '-',
        // numeral: true,
        blocks: [2, 3],
      },
    },
    existedEmails: [],
    existedPhones: [],

    autoCreateContactThreadEnabled: false,
    autoCreateContactThreadFunnelTypePoint: null,
    autoCreateContactThreadName: '',
  }),
  watch: {
    reloadContent(newValue) {
      if (newValue) {
        this.contact.organization = this.$store.getters['modal/getModalData']
      }
    },
    '$route.name': function () {
      this.organizations = []
      this.contact = {
        locale: 'pl_pl',
        mailAddresses: [
          '',
        ],
        lastName: '',
        phoneNumbers: [
          {
            value: '',
            countryCode: {
              code: 'PL',
              dial_code: '+48 ',
              name: 'Poland',
            },
          },
        ],
        deliveryAddresses: [],
        firstName: '',
        status: '',
        addressCity: '',
        addressCountry: '',
        addressNumber: '',
        addressPostalCode: '',
        addressStreet: '',
        addressCorrespondenceCountry: '',
        addressCorrespondenceCity: '',
        addressCorrespondencePostalCode: '',
        addressCorrespondenceStreet: '',
        addressCorrespondenceNumber: '',
        agreeMarketing: false,
        organization: '',
        note: '',
        userGroups: [],
        // Agreement
        personalId: '',
        birthDate: '',
        familyName: '',
        maritalStatus: '',
        financialPosition: '',
        personIdentityDocuments: [],
        contactTypes: [],
      }
      this.assignedUsers = []
      this.init()
    },
  },
  async mounted() {
    this.init()
  },
  methods: {
    async getGlobalSettings() {
      try {
        const reponse = await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)

        Object.assign(this, reponse.data.item)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    isRequired(field) {
      return this.requiredFields.includes(field) ? 'required' : ''
    },
    async init() {
      this.loading = true
      this.getGlobalSettings()
      this.onGetContactTags()
      this.loadGroups()

      await this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
        .then(() => {
          // eslint-disable-next-line prefer-destructuring
          this.contact.status = this.statuses[0]?.id || ''
        })

      if (!this.isCreateMode) {
        const contactId = this.$route.params?.contactId || this.id
        this.$store.dispatch(`singleContact/${GET_EDITED_CONTACT}`, contactId)
          .then(res => {
            this.contact = res
            this.contact.note = (this.contact.note || '').replaceAll('<br />', '\n') || ''
            this.assignedUsers = [...res.contactAssignedUsers.map(item => item.user)]
            this.contact.status = res.status.id
            this.contact.birthDate = res.birthDate?.date || ''
            this.contact.locale = res.locale ? res.locale : 'pl_pl'
            if (res.phoneNumbers?.length) {
              this.contact.phoneNumbers = res.phoneNumbers.map(phoneObject => {
                const { phone } = phoneObject
                const phoneItem = {
                  value: phone || '',
                  countryCode: {
                    code: 'PL',
                    dial_code: '+48 ',
                    name: 'Poland',
                  },
                }
                const code = countryCodes.find(({ dial_code }) => phone.includes(dial_code.trim()))
                if (code) {
                  phoneItem.value = phone.replace(code.dial_code.trim(), '')
                  phoneItem.countryCode = code
                }
                phoneItem.isPrimary = res.phone === phoneObject.phone

                return phoneItem
              })
              this.contact.phoneNumbers.sort((a, b) => Number(b?.isPrimary) - Number(a?.isPrimary))
            } else {
              this.contact.phoneNumbers = [
                {
                  value: this.contact?.phone ? this.contact.phone : '',
                  countryCode: {
                    code: 'PL',
                    dial_code: '+48 ',
                    name: 'Poland',
                  },
                },
              ]
            }
            if (res.mailAddresses?.length) {
              this.contact.mailAddresses = res.mailAddresses.filter(item => item.mail !== res.email).map(email => email.mail)
              if (res.email) this.contact.mailAddresses.unshift(res.email)
            } else if (res.email) this.contact.mailAddresses.unshift(res.email)
            else this.contact.mailAddresses.push('')

            // eslint-disable-next-line
            const birthDate = typeof this.contact.birthDate === 'string' ? this.contact.birthDate : (this.contact.birthDate?.date || '')
            this.$set(this.contact, 'birthDate', birthDate)
            this.contact.personIdentityDocuments.forEach(doc => {
              // eslint-disable-next-line
              const expirationDate = typeof doc.expirationDate === 'string' ? doc.expirationDate : (doc.expirationDate?.date || '')
              this.$set(doc, 'expirationDate', expirationDate)
              // eslint-disable-next-line
              const releaseDate = typeof doc.releaseDate === 'string' ? doc.releaseDate : (doc.releaseDate?.date || '')
              this.$set(doc, 'releaseDate', releaseDate)
            })

            if (res.organization) {
              this.isCompany = true
              this.contact.organization = res.organization
            } else {
              this.isCompany = false
              this.contact.organization = ''
            }

            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      } else {
        this.assignedUsers = [this.currentUser]
        this.loading = false
      }
    },
    onGetContactTags() {
      this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
        .then(res => {
          this.tags = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    changeOrganization(event) {
      this.$set(this.contact, 'organization', event)
    },
    existedPhone(phone) {
      return this.existedPhones.filter(ob => ob.contacts.find(obContact => obContact.id !== this.contact?.id)).find(contact => contact.phone === ((phone.countryCode?.dial_code || '') + phone.value))
    },
    existedMail(email) {
      return this.existedEmails.filter(ob => ob.contacts.find(obContact => obContact.id !== this.contact?.id)).find(contact => contact.mail === email)
    },
    checkExistedPhones() {
      this.existedPhones = []
      const { phoneNumbers } = this.contact
      const preparedPhones = phoneNumbers.filter(phone => phone.value).map(phone => (phone.countryCode?.dial_code || '') + phone.value)
      axiosIns.post('1/contacts/getExistsByPhones', preparedPhones)
        .then(res => {
          this.existedPhones = res.data.data?.exists || []
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    checkExistedEmails() {
      this.existedEmails = []
      const { mailAddresses } = this.contact
      let preparedMail = mailAddresses.filter(mail => !!mail)
      preparedMail = preparedMail?.filter(Boolean)
      axiosIns.post('1/contacts/getExistsByEmails', preparedMail)
        .then(res => {
          this.existedEmails = res.data.data?.exists || []
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    copyMainAddress() {
      this.$set(this.contact, 'addressCorrespondenceCountry', this.contact.addressCountry)
      this.$set(this.contact, 'addressCorrespondenceCity', this.contact.addressCity)
      this.$set(this.contact, 'addressCorrespondencePostalCode', this.contact.addressPostalCode)
      this.$set(this.contact, 'addressCorrespondenceStreet', this.contact.addressStreet)
      this.$set(this.contact, 'addressCorrespondenceNumber', this.contact.addressNumber)
    },
    loadGroups() {
      this.$store.dispatch(`groups/${GET_GROUPS}`)
        .then(res => {
          this.groups = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    onCancel() {
      if (this.isModalView) {
        this.$emit('close', null)
      } else if (!this.isCreateMode) {
        this.$router.push(`/contact/${this.contact.id}`)
      } else {
        this.$router.push('/contacts')
      }
    },
    createCompany() {
      if (this.$route.name !== 'new-contacts') {
        this.addOrganization(false)
      } else {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('RedirectToCompanyCreate'), true, this.$i18n.t('Continue'))
          .then(() => {
            this.$router.push({ name: 'organizationsNew' })
          })
      }
    },
    addOrganization(isEdited = false) {
      const payload = {
        modalType: 'organization',
        modalTitle: this.$i18n.t('NewOrganization'),
        okButtonTitle: this.$i18n.t('Save'),
        size: 'lg',
      }
      if (isEdited) {
        payload.data = this.contact.organization
        payload.modalTitle = this.$i18n.t('EditOrganization')
      }
      this.$store.commit(`modal/${OPEN_MODAL}`, payload)
    },
    reloadOrganizations(search, loading) {
      if (search.length) {
        loading(true)
        this.$store.dispatch(`organizations/${GET_ORGANIZATIONS}`, search)
          .then(res => {
            this.organizations = res.data.items
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => {
            loading(false)
          })
      }
    },
    changeAssignedUsers(payload) {
      this.assignedUsers = [...payload]
    },
    // Delivery Addresses
    editAddress(address) {
      if (address?.index > -1) {
        // Edit action
        this.$set(this.contact.deliveryAddresses, address.index, address)
      } else {
        // Create action
        // eslint-disable-next-line
        delete address.index
        this.contact.deliveryAddresses.push(address)
      }
    },
    deleteAddress(index) {
      this.$delete(this.contact.deliveryAddresses, index)
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.newContactForm.validate().then(success => {
          if (success) {
            resolve(true)
            this.submitForm()
          } else {
            reject()
          }
        })
      })
    },
    async submitForm() {
      this.loading = true
      const {
        firstName,
        lastName,
        mailAddresses,
        phoneNumbers,
        status,
        agreeMarketing,
        note,
        userGroups,
        identityCardNumber,
        birthDate,
        familyName,
        maritalStatus,
        financialPosition,
        personIdentityDocuments,
        personalId,
        locale,
        contactTypes,
        deliveryAddresses,
        position,
      } = this.contact

      if (phoneNumbers.filter(phone => this.existedPhone(phone)).length) {
        this.showToast('error', this.$i18n.t('PhoneExist'))
        this.loading = false
        return
      }

      if (mailAddresses.filter(mail => this.existedMail(mail)).length) {
        this.showToast('error', this.$i18n.t('EmailExist'))
        this.loading = false
        return
      }

      const existedContactTypes = contactTypes.filter(type => type.id).map(type => this.getObjectId(type)).filter(Boolean)
      const newContactTypes = contactTypes.filter(type => !type?.id && type?.name)

      if (this.checkRequiredPermissions([this.$roles.SETTINGS]) && newContactTypes.length) {
        const typesPayload = newContactTypes.map(({ name }, index) => ({ color: '#fff', name, position: (index + 1 + this.tags.length) }))

        const response = await axiosIns.put('1/settings/contactTypes', typesPayload)
        if (response.data.data?.items?.length) {
          existedContactTypes.push(...response.data.data?.items.map(({ id }) => id))
        }
      }

      const contact = {
        deliveryAddresses,
        locale,
        firstName,
        lastName,
        mailAddresses,
        mailAddress: mailAddresses[0] || null,
        phoneNumbers: phoneNumbers.filter(phone => phone.value && !this.existedPhone(phone))
          .map(phone => (phone.countryCode?.dial_code || '') + phone.value),
        status,
        agreeMarketing,
        note: note.replaceAll('\n', '<br />'),
        identityCardNumber,
        birthDate,
        familyName,
        maritalStatus: maritalStatus || null,
        financialPosition: financialPosition || null,
        position: position || null,
        contactTypes: existedContactTypes,
        personIdentityDocuments: personIdentityDocuments.filter(doc => doc.type && doc.number).map(e => ({ ...e, type: e.type || null })),
        personalId: personalId ? String(personalId) : null,
        isOrganization: this.isCompany,
      }
      contact.phoneNumber = contact.phoneNumbers?.length ? contact.phoneNumbers[0] : null

      if (deliveryAddresses.length) {
        const toUpdate = deliveryAddresses.filter(address => address?.id)
        const toCreate = deliveryAddresses.filter(address => !address?.id)
        const ids = []
        if (toUpdate.length) {
          const res = await axiosIns.patch('1/addresses', toUpdate)
          if (res?.data?.data?.items) ids.push(...res?.data?.data?.items.map(item => item.id))
        }
        if (toCreate.length) {
          const res = await axiosIns.post('1/addresses', toCreate)
          if (res?.data?.data?.items) ids.push(...res?.data?.data?.items.map(item => item.id))
        }

        contact.deliveryAddresses = ids
      }

      contact.status = contact.status ? this.getObjectId(contact.status) : this.getObjectId(this.statuses[0])

      const { isCreateMode } = this
      const action = isCreateMode ? CREATE_CONTACT : EDIT_CONTACT

      if (!isCreateMode) {
        contact.id = this.contact?.id?.toString() || this.id
      }

      const contactAssignedUsers = this.assignedUsers

      if (contactAssignedUsers === undefined) {
        this.assignedUsers = null
      } else {
        contactAssignedUsers.forEach((_contact, index) => {
          if (typeof (_contact) === 'object') contactAssignedUsers[index] = _contact.id.toString()
        })
      }
      contact.assignedUsers = contactAssignedUsers

      if (this.isCompany) {
        contact.organization = this.contact.organization?.id?.toString() || null
      }

      const {
        addressCity,
        addressCountry,
        addressNumber,
        addressPostalCode,
        addressStreet,
        addressCorrespondenceCountry,
        addressCorrespondenceCity,
        addressCorrespondencePostalCode,
        addressCorrespondenceStreet,
        addressCorrespondenceNumber,
      } = this.contact
      contact.addressCity = addressCity
      contact.addressCountry = addressCountry
      contact.addressNumber = addressNumber
      contact.addressPostalCode = addressPostalCode ? `${addressPostalCode.toString().substring(0, 2)}-${addressPostalCode.toString().substring(2)}` : addressPostalCode
      contact.addressStreet = addressStreet
      contact.addressCorrespondenceCountry = addressCorrespondenceCountry
      contact.addressCorrespondenceCity = addressCorrespondenceCity
      contact.addressCorrespondencePostalCode = addressCorrespondencePostalCode ? `${addressCorrespondencePostalCode.toString().substring(0, 2)}-${addressCorrespondencePostalCode.toString().substring(2)}` : addressCorrespondencePostalCode
      contact.addressCorrespondenceStreet = addressCorrespondenceStreet
      contact.addressCorrespondenceNumber = addressCorrespondenceNumber

      // Groups
      userGroups.forEach((group, index) => {
        userGroups[index] = group?.id?.toString() || group
      })
      contact.userGroups = userGroups

      // contacts
      this.$store.dispatch(`contacts/${action}`, contact)
        .then(async res => {
          const text = isCreateMode ? this.$i18n.t('success.contactCreated') : this.$i18n.t('success.contactUpdated')
          this.showToast('success', text, this.$i18n.t('success.saved'))
          const id = res
          setTimeout(async () => {
            if (!this.isModalView) {
              if (id) {
                if (isCreateMode && this.autoCreateContactThreadEnabled && this.autoCreateContactThreadFunnelTypePoint) {
                  await this.createThread(id)
                }

                await this.$router.push(`/contact/${id}`)
              } else await this.$router.push('/contacts')
            } else {
              let contactThread = null
              if (isCreateMode && this.autoCreateContactThreadEnabled && this.autoCreateContactThreadFunnelTypePoint) {
                contactThread = await this.createThread(id)
              }
              this.$emit('close', {
                contactThread,
                ...contact,
                id,
                organization: this.contact.organization,
              })
            }
          }, 100)
        })
        .catch(async err => {
          // eslint-disable-next-line
          if (err?.response?.data?.message === 'LIMIT_REACHED') {
            await this.showAlert('error', '', this.$i18n.t('BuyContacts'), false)
            return
          }
          // console.log(err)
          if (err?.response?.data?.message) this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }).finally(() => {
          this.loading = false
        })
    },
    async createThread(contactId) {
      const thread = {
        name: this.autoCreateContactThreadName ? this.autoCreateContactThreadName : `${this.$i18n.t('ContactThread')} ${this.$i18n.t('Styles.Primary').toLowerCase()}`,
        predictedValue: 0,
        predictedValueCurrency: 'PLN',
        assignedUsers: [this.getObjectId(this.$store.getters['auth/getCurrentUser'])],
        contact: contactId,
      }

      const resp = await axiosIns.post(`1/contacts/${contactId}/threads`, thread)

      await axiosIns.post(`1/contacts/threads/${resp.data.data.items[0].id}/funnelProcesses`, {
        contactThreadFunnelTypePoint: this.getObjectId(this.autoCreateContactThreadFunnelTypePoint),
        status: 'OPEN',
      })
      return { ...thread, id: resp?.data?.data?.items[0]?.id }
    },
  },
}
</script>

<style scoped>

</style>

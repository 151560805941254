const units = [
  'PIECE',
  'TONNE',
  'KILOGRAM',
  'GRAM',
  'LITRE',
  'MILLILITRE',
  'PACK',
  'BOX',
  'KILOMETER',
  'METER',
  'CENTIMETER',
  'MILLIMETER',
  'SQUARE_METER',
  'CUBIC_METER',
  'HOUR',
]

export default units
